import { HydraBase } from "./api";
import { Media } from "./media";

export type Deal = HydraBase & {
  agency: null | string;
  briefSummary: string;
  amount: number;
  archived: boolean;
  commissionPercentage: number;
  contractingPartyType: number;
  createdAt: string;
  startedAt: string;
  currency: string;
  deliverables: string[];
  depositPercentage: null | number;
  name: string;
  updatedAt: string;
  talent: HydraBase & {
    company: string;
    mediaLogo: Media | null;
    name: string;
  };
  brand: HydraBase & {
    company: string;
    mediaLogo: Media | null;
    name: string;
  };
  quotations: Array<
    HydraBase & {
      status: number;
    }
  >;
  invoices: Array<
    HydraBase & {
      status: number;
    }
  >;
  contracts: Array<
    HydraBase & {
      status: number;
    }
  >;
  medias: Media[];
};

export type Deliverable = HydraBase & {
  amount: number;
  brandSocialNetwork: null | string;
  contentTitle: string;
  createdAt: string;
  scheduledAt: string;
  sendingScriptDeadlineAt: null | string;
  talentInstructions: string;
  updatedAt: string;
  validationDeadlineAt: null | string;
  deal?: HydraBase & {
    agency: Deal["agency"];
    brand: Deal["brand"];
    name: Deal["name"];
    talent: Deal["talent"];
  };
  deliverableType: HydraBase & {
    name: string;
    socialNetwork: HydraBase & {
      name: string;
    };
  };
  talentSocialNetwork: HydraBase & {
    followersCount: number;
    socialNetwork: HydraBase & {
      name: string;
    };
    username: string;
    viewersCount: null | number;
  };
};

export enum InvoiceStatus {
  DRAFT,
  SENT,
  PAID,
  INVOICE_NUMBER_PENDING,
}

export enum PaymentStatus {
  PENDING_ADVANCE = 'pending_advance',
  PAYMENT_ON_DUE_DATE = 'payment_on_due_date',
}

export enum InvoiceType {
  DEPOSIT,
  BALANCE,
}

export type Invoice = HydraBase & {
  createdAt: string;
  documentNumber: number;
  dueDate: string;
  invoiceNumberConfirmedAt: null | string;
  invoiceType: number;
  issuedAt: string;
  issuerType: InvoiceType;
  items: string[];
  currency: string;
  media: HydraBase & {
    downloadPublicLink: string;
    name: string;
    readPublicLink: string;
  };
  notes: string;
  paidAt: null | string;
  purchaseOrderNumber: null | number;
  sentAt: string;
  status: InvoiceStatus;
  taxPercentage: number;
  terms: string;
  paymentStatus: PaymentStatus,
  totalBeforeTax: number;
  updatedAt: string;
  recipientCompany: HydraBase & {
    invoiceNumberConfirmationFlow: boolean;
    mediaLogo: Media | null;
    name: string;
    signatureContractDefaultFlow: boolean;
  };
  issuerCompany: HydraBase & {
    invoiceNumberConfirmationFlow: boolean;
    mediaLogo: Media | null;
    name: string;
    signatureContractDefaultFlow: boolean;
  };
  deal: HydraBase & {
    name: string;
    brand: HydraBase & {
      name: string;
    };
    contractingCompany: HydraBase & {
      name: string;
      mediaLogo: HydraBase & {
        downloadPublicLink: string;
        name: string;
        readPublicLink: string;
      };
    };
  };
};

export enum ContractStatus {
  DRAFT,
  SENT_FOR_APPROVAL,
  SENT_FOR_SIGNATURE,
  COMPLETED,
  DECLINED,
}

export type Contract = HydraBase & {
  completedAt: string;
  createdAt: string;
  externalDocumentId: string;
  issuerContactSignedAt: string;
  issuerType: number;
  recipientContactSignedAt: string;
  sentForApprovalAt: string;
  sentForSignatureAt: string;
  specialTerms: string;
  status: ContractStatus;
  updatedAt: string;
  deal: HydraBase & {
    name: string;
  };
  template: HydraBase & {
    language: string;
    name: string;
  };
  issuerCompany: HydraBase & {
    agency: null | string;
    brand: string;
    legalForm: string;
    name: string;
    platform: null | string;
    signatureEmailsRecipientCompanyContact: null | string;
    talent: null | string;
  };
  issuerCompanyContact: HydraBase & {
    email: string;
    firstName: string;
    jobTypes: number[];
    lastName: string;
    locale: string;
    phone: string;
  };
  recipientCompany: HydraBase & {
    agency: null | string;
    brand: string;
    legalForm: string;
    name: string;
    platform: null | string;
    signatureEmailsRecipientCompanyContact: null | string;
    talent: null | string;
  };
  recipientCompanyContact: HydraBase & {
    email: string;
    firstName: string;
    jobTypes: number[];
    lastName: string;
    locale: string;
    phone: string;
  };
  media: HydraBase & {
    downloadPublicLink: string;
    name: string;
    readPublicLink: string;
  };
};
