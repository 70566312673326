import api from "@/services/api.service";
import { HydraListResponse } from "@/types/api";
import { Contract, Deal, Deliverable, Invoice } from "@/types/deals";
import { CalendarEvent } from "@/types/talent";

class DealsService {
  async fetchDeals(params?: {
    "createdAt[after]"?: string;
    "createdAt[before]"?: string;
    archived?: boolean;
    "brand.name"?: string;
    "order[startedAt]"?: "asc" | "desc";
    itemsPerPage?: number;
    pagination?: boolean;
  }): Promise<HydraListResponse<Deal>> {
    return (
      await api.get<HydraListResponse<Deal>>("deals", {
        params,
      })
    ).data;
  }

  async getDeal(id: string): Promise<Deal> {
    return (await api.get<Deal>(`deals/${id}`)).data;
  }

  async fetchDealDeliverables(
    id: string
  ): Promise<HydraListResponse<Deliverable>> {
    return (
      await api.get<HydraListResponse<Deliverable>>(`deals/${id}/deliverables`)
    ).data;
  }

  async fetchDealCalendarEvents(
    id: string
  ): Promise<HydraListResponse<CalendarEvent>> {
    return (
      await api.get<HydraListResponse<CalendarEvent>>(`calendar_events`, {
        params: {
          "deliverable.deal": id,
        },
      })
    ).data;
  }

  async fetchDeliverables(params?: {
    "scheduledAt[after]"?: string;
  }): Promise<HydraListResponse<Deliverable>> {
    return (
      await api.get<HydraListResponse<Deliverable>>("deliverables", {
        params,
      })
    ).data;
  }

  async fetchInvoices(
    params?: {
      deal?: string | string[];
      ["order[createdAt]"]?: "desc" | "asc";
      ["order[issuedAt]"]?: "desc" | "asc";
    } & any
  ): Promise<HydraListResponse<Invoice>> {
    return (
      await api.get<HydraListResponse<Invoice>>("invoices", {
        params,
      })
    ).data;
  }

  async fetchContracts(params?: {
    deal?: string | string[];
  }): Promise<HydraListResponse<Contract>> {
    return (
      await api.get<HydraListResponse<Contract>>("contracts", {
        params,
      })
    ).data;
  }
}

export default new DealsService();
